import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PDFViewer = () => {
  const { pdfPath } = useParams();
  const url = decodeURIComponent(pdfPath);

  const calculateZoom = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1920) {
      return '115%';
    } else {
      const proportion = (screenWidth / 1920) * 100;
      return `${Math.max(47, proportion)}%`;
    }
  };

  const [zoomLevel, setZoomLevel] = useState(calculateZoom);

  useEffect(() => {
    const handleResize = () => {
      setZoomLevel(calculateZoom());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const src = `/viewer.html?file=${encodeURIComponent(url)}#zoom=${zoomLevel}`;

  return (
    <>
      <iframe className='section-padding-50-0 '
        title="PDF Viewer"
        src={src}
        style={{ border: 'none', width: '100%', height: '99vh' }}
      />
    </>
  );
};

export default PDFViewer;
