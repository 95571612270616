import React from 'react';
import { openPdf } from '../openPDF';
import { useTranslation } from 'react-i18next';

const GridItem = ({ pdfPath, title, iconSrc, delay }) => {
  const style = {
    animationName: 'fadeInUp',
    animationDuration: '1500ms',
    animationDelay: delay,
    animationFillMode: 'both',
    visibility: 'visible',
  };


  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex mb-4" style={style}>
      <a onClick={() => openPdf(pdfPath)} className="service_single_content grediant-borders box-shadow d-flex">
        <div className="service_icon">
          <img draggable="false" src={iconSrc} alt="" />
        </div>
        <div className="service_content">
          <h6>{title}</h6>
        </div>
      </a>
    </div>
  );
};

const PageMatiere = () => {
  const { t } = useTranslation();
  const ContentList = t('TopologieContent', { returnObjects: true });

  const formatText = (text) => {
    const replacedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    return { __html: replacedText };
  };

  return (
    <>
    <div>
  

  <div className="clearfix" />
  <div className='container'>

<section className="darky how" style={{paddingTop: 125, paddingBottom:50}}>
    <div className="container">
      <div className="section-heading text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider" style={{transform: 'rotate(180deg)'}} />
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">{t('TopologieTitre')}</h2>
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mt-15 mb-50" alt="divider" />
        </div>
        <p className="wow fadeInUp " data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp', maxWidth:'100%', textAlign: 'justify'}}>
        <ul>
          {ContentList.map((item, index) => (
            <li key={index} className="listItemStyleContent" dangerouslySetInnerHTML={formatText(item)}></li>
          ))}
        </ul>
        </p>
      </div>

      
    </div>
  </section>


</div>

    </div>



 

</>

  );
};

export default PageMatiere;
