// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import translationEN from '../locales/en/translation.json';
// import translationFR from '../locales/fr/translation.json';

// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   fr: {
//     translation: translationFR,
//   },
// };

// // Fonction pour récupérer la langue sélectionnée à partir du stockage local
// export const getSelectedLanguage = () => {
//   return localStorage.getItem('selectedLanguage') || 'fr'; // 'fr' est la langue par défaut
// };

// // Fonction pour changer la langue et mettre à jour le stockage local
// export const changeLanguage = (newLanguage) => {
//   i18n.changeLanguage(newLanguage); // Change la langue dans i18n

//   // Enregistre la nouvelle langue dans le stockage local
//   localStorage.setItem('selectedLanguage', newLanguage);
// };

// i18n
//   .use(initReactI18next)
//   .init({
//     resources,
//     lng: getSelectedLanguage(), // Utilisez la langue récupérée du stockage local
//     fallbackLng: 'fr',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationFR from '../locales/fr/translation.json';

const resources = {
  fr: {
    translation: translationFR,
  },
};

// Fonction pour récupérer la langue sélectionnée à partir du stockage local
export const getSelectedLanguage = () => {
  return 'fr'; // Force 'fr' comme langue par défaut et unique
};

// Fonction pour changer la langue et mettre à jour le stockage local
export const changeLanguage = (newLanguage) => {
  // Cette fonction ne fait plus rien, mais est conservée pour une utilisation future
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr', // Force la langue à 'fr'
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
