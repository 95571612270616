import React from 'react';
import { openPdf } from '../openPDF';
import { useTranslation } from 'react-i18next';

const GridItem = ({ pdfPath, title, iconSrc, delay }) => {
  const style = {
    animationName: 'fadeInUp',
    animationDuration: '1500ms',
    animationDelay: delay,
    animationFillMode: 'both',
    visibility: 'visible',
  };
  const imageStyle = {
    width: '60%', 
    borderRadius: '5px' 
  };


  return (
    <div className="col-12 col-sm-12 col-md-5 col-lg-5 d-flex mb-5" style={style}>
      <button onClick={() => openPdf(pdfPath)} className="service_single_content gradient-borders box-shadow d-flex align-items-center transparent-bg justify-content-center">
        <div className="service_icon">
          <img draggable="false" src={iconSrc} alt={`Icône de ${title}`} style={imageStyle} />
        </div>
        <div className="service_content">
          <h6>{title}</h6>
        </div>
      </button>
    </div>
  );
};

const PageMatiere = () => {
  const { t } = useTranslation();
  const ContentList = t('CommContent', { returnObjects: true });

  const formatText = (text) => ({
    __html: text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'),
  });

  const gridItems = [
    { pdfPath: "toeic_certificate.pdf", title: "Attestation du TOEIC", iconSrc: "img/core-img/uk.png", delay: "0.3s" },
    
  ];


  
  return (
    <>
    <div>
  

  <div className="clearfix" />
  <div className='container'>

<section className="darky how" style={{paddingTop: 125, paddingBottom:50}}>
    <div className="container">
      <div className="section-heading text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider" style={{transform: 'rotate(180deg)'}} />
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">{t('CommTitre')}</h2>
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mt-15 mb-50" alt="divider" />
        </div>
        <p className="wow fadeInUp " data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp', maxWidth:'100%', textAlign: 'justify'}}>
        <ul>
          {ContentList.map((item, index) => (
            <li key={index} className="listItemStyleContent" dangerouslySetInnerHTML={formatText(item)}></li>
          ))}
        </ul>
        </p>
      </div>

      <div className="row">
              {gridItems.map((item, index) => (
                <GridItem key={index} {...item} />
              ))}
            </div>
    </div>
  </section>


</div>

    </div>



 

</>

  );
};

export default PageMatiere;
