import React from 'react';
import { openPdf } from '../openPDF';

const GridItem = ({ pdfPath, title, iconSrc, delay }) => {
  const style = {
    animationName: 'fadeInUp',
    animationDuration: '1500ms',
    animationDelay: delay,
    animationFillMode: 'both',
    visibility: 'visible',
  };

  return (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex mb-4" style={style}>
      <a onClick={() => openPdf(pdfPath)} className="service_single_content grediant-borders box-shadow d-flex">
        <div className="service_icon">
          <img draggable="false" src={iconSrc} alt="" />
        </div>
        <div className="service_content">
          <h6>{title}</h6>
        </div>
      </a>
    </div>
  );
};

const PageMatiere = () => {

  return (
    <>
    <div>
  

  <div className="clearfix" />
  <div className='container'>

<section className="darky how" style={{paddingTop: 125, paddingBottom:50}}>
    <div className="container">
      <div className="section-heading text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider" style={{transform: 'rotate(180deg)'}} />
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Probabilités / Statistiques</h2>
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mt-15 mb-50" alt="divider" />
        </div>
        <p className="wow fadeInUp " data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp', maxWidth:'100%', textAlign: 'justify'}}>
        Vestibulum morbi vestibulum ante praesent scelerisque ornare a parturient nascetur feugiat suspendisse nostra condimentum malesuada.
        Vel fermentum magna a a scelerisque dignissim luctus accumsan interdum auctor fringilla inceptos nec molestie a a netus vestibulum posuere ridiculus eleifend.
        Parturient parturient mi interdum nostra cubilia turpis scelerisque a nostra posuere congue dui dignissim egestas parturient cubilia suspendisse a ornare rutrum pharetra vel a vestibulum.
        A habitant elit habitant erat magnis a adipiscing non parturient eu blandit tempor condimentum ad inceptos ullamcorper mi suscipit parturient adipiscing consectetur id parturient suscipit non dapibus vestibulum nullam.
        Habitant ligula a magna ad a senectus a ullamcorper id semper ante eu malesuada at vestibulum at ullamcorper orci nibh vel vulputate class hac a donec.
        </p>
      </div>

     
    </div>
  </section>


</div>

    </div>



 

</>

  );
};

export default PageMatiere;
