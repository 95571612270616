import React from 'react';

function ComponentName() {
  return (
<div className='container'>

<section className="darky how section-padding-100-70" style={{paddingTop: 50}}>
    <div className="container">
      <div className="section-heading text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
          <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider" />
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor</h2>
        <p className="wow fadeInUp" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo.</p>
      </div>

      <div className="row" data-masonry='{"percentPosition": true }' >





      <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/1.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Technologies de la Blockchain</h6>
    </div>
  </a>
</div>
        
        <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/2.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Programmation impérative</h6>
    </div>
  </a>
</div>

<div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/3.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Rapports scientifiques</h6>
    </div>
  </a>
</div>





<div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/4.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
              <h6>Modélisation d'optimalité </h6>
            </div>
          </a>
        </div>
        
        <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/3.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
              <h6>Théorie des langages
              </h6>
            </div>
          </a>
        </div>

         <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/2.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
              <h6>Processus stochastiques </h6>
            </div>
          </a>
        </div>




        <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/1.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Technologies de la Blockchain hechekoum Test</h6>
    </div>
  </a>
</div>
        
        <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/2.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Programmation impérative</h6>
    </div>
  </a>
</div>

<div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/3.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Rapports scientifiques </h6>
    </div>
  </a>
</div>




<div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/1.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Technologies de la Blockchain</h6>
    </div>
  </a>
</div>
        
        <div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/2.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Programmation impérative</h6>
    </div>
  </a>
</div>

<div className="col-12 col-md-4 col-lg-4 d-flex">
  <a href="/template" className="service_single_content grediant-borders box-shadow wow fadeInUp d-flex align-items-center" style={{ width: '100%' }}>
    <div className="service_icon v2">
      <img draggable="false" src="img/icons/3.png" className="white-icon" alt="" />
    </div>
    <div className="service_content" >
      <h6>Rapports scientifiques</h6>
    </div>
  </a>
</div>





      </div>
    </div>
  </section>



    </div>
  );
}

export default ComponentName;