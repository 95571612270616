import React, { useEffect } from 'react'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Ajouté pour utiliser i18next dans ce composant
import HomePage from './components/HomePage';
import PageMatiere from './components/PageMatiere';
import Navbar from './components/Navbar';
import ContactPage from './components/ContactPage';
import CategorieMatieresTerminees from './components/CategorieMatieresTerminees';
import PDFViewer from './components/PDFViewer';

import SignalFourier from './components/MatTer/TraitementSignalFourier';
import Calculus from './components/MatTer/Calculus';
import Logique from './components/MatTer/Logique';
import Num from './components/MatTer/Numeration';
import ProbaStat from './components/MatTer/Proba_Stat';
import Markov from './components/MatTer/Markov';
import Lang from './components/MatTer/TheorieLang';
import Diff from './components/MatTer/Differentiel';
import Topo from './components/MatTer/Topologie';
import Para from './components/MatTer/Parallelisation';
import Turing from './components/MatTer/Turing';
import AlgoAlg from './components/MatTer/AlgorithmiqueAlgebrique';
import LangagesProg from './components/MatTer/LangagesProg';
import AlgoAv from './components/MatTer/AlgoAvance';

import SysExpArch from './components/MatTer/SysExpArch';
import OptCont from './components/MatTer/OptimisationCont';
import DataProbaStat from './components/MatTer/DataProbaStat';
import Comm from './components/MatTer/ExpEtComm';
import Interpo from './components/MatTer/Interpolation';



const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

const App = () => {
  const { i18n } = useTranslation(); 

  useScript(`${process.env.PUBLIC_URL}/js/masonry.pkgd.min.js`);
  useScript(`${process.env.PUBLIC_URL}/js/jquery.min.js`);
  useScript(`${process.env.PUBLIC_URL}/js/popper.min.js`);
  useScript(`${process.env.PUBLIC_URL}/js/bootstrap.min.js`);
  useScript(`${process.env.PUBLIC_URL}/js/script.js`);
  useScript(`${process.env.PUBLIC_URL}/js/js.js`);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/TemplateMatiere" element={<PageMatiere />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/CategorieMatieresTerminees" element={<CategorieMatieresTerminees />} />
        <Route path="/view-pdf/:pdfPath" element={<PDFViewer />} />
        <Route path="/Traitement_du_signal_Analyse_de_Fourier" element={<SignalFourier />} />
        <Route path="/Calculus" element={<Calculus />} />
        <Route path="/Logique" element={<Logique />} />
        <Route path="/Numeration" element={<Num />} />
        <Route path="/Probabilites_Statistiques" element={<ProbaStat />} />
        <Route path="/Markov" element={<Markov />} />
        <Route path="/TheorieDesLangages" element={<Lang />} />
        <Route path="/CalculDifferentielClassique" element={<Diff />} />
        <Route path="/TopologieAnalyseHilbertienne" element={<Topo />} />
        <Route path="/Parallelisation" element={<Para />} />
        <Route path="/MachinesTuringDecidabilite" element={<Turing />} />
        <Route path="/AlgorithmiqueAlgebrique" element={<AlgoAlg />} />
        <Route path="/LangagesDeProgrammation" element={<LangagesProg />} />
        <Route path="/AlgorithmiqueAvanceeClassique" element={<AlgoAv />} />
        <Route path="/SystemeExploitationArchitecture" element={<SysExpArch />} />
        <Route path="/OptimisationContinue" element={<OptCont />} />
        <Route path="/DataAnalyseProbabilitesStatistique" element={<DataProbaStat />} />
        <Route path="/ExpressionCommunication" element={<Comm />} />
        <Route path="/InterpolationQuadrature" element={<Interpo />} />

      </Routes>
    </Router>
  );
};

export default App;
