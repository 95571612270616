import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';



const ContactPage = () => {
  const { t } = useTranslation();
  const [texteCopie, setTexteCopie] = useState(t('click_to_copy'));
  const [texteCopie2, setTexteCopie2] = useState(t('mail'));
  const [svgActuel, setSvgActuel] = useState('svg1');
  const [fade, setFade] = useState(false);
  const [isEmailIconActive, setIsEmailIconActive] = useState(true); // Utilisé pour contrôler l'affichage de l'icône
  const [emailText, setEmailText] = useState(t('click_to_copy')); // Utilisé pour contrôler le texte


  
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  
  const scaleStyle = {
    transform: isHovered ? 'scale(1.2)' : 'scale(1)',
    transition: 'transform 250ms ease-in-out, opacity 0.25s ease-in-out',
    position: 'absolute', // Nécessaire pour superposer les images
    // L'opacité est contrôlée par l'état isEmailIconActive, donc pas besoin de le modifier ici
  };
  

  const copierDansPressePapier = (texte) => {
    const el = document.createElement('textarea');
    el.value = texte;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const copierEmail = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // Utilisation de l'API Clipboard si disponible
        await navigator.clipboard.writeText('contact@tristanc.engineer');
      } else {
        // Méthode de secours pour Safari et autres navigateurs
        copierDansPressePapier('contact@tristanc.engineer');
      }
      setIsEmailIconActive(false);
      setTexteCopie2(t('email_copied'));
    } catch (err) {
      console.error('Erreur lors de la copie', err);
      setTexteCopie2(t('error_on_copy'));
    }
  };

  const toggleEmailState = async () => {
    if (isEmailIconActive) {
      await copierEmail();
    } else {
      setIsEmailIconActive(true);
      setTexteCopie2(t('click_to_copy'));
    }
  };

  return (
    <div className="contact_us_area section-padding-100-70" id="contact" >
      <div className="container">
        <div className="section-heading text-center" style={{paddingBottom: 50, marginBottom: 0}}>
          <div className="dream-dots justify-content-center fadeInUp" data-wow-delay="0.2s">
            <img src="img/svg/divider-01.svg" width={100} className="mb-15" alt="divider" />
          </div>
          <h2 className="fadeInUp" data-wow-delay="0.3s" style={{paddingTop: 20}}>{t('contact_me')}</h2>
        </div>

        <section className="our_team_area clearfix" id="team" style={{paddingTop:'25px'}}>
          <div className="container">
            <div className="row justify-content-center">

            <div className="col-12 col-sm-6 col-lg-4 containerSVG" onClick={toggleEmailState}>
                <div className="single-team-member fadeInUp" data-wow-delay="0.2s"  style={{marginBottom: 0}}>
             
                <div 
    className="team-member-thumb" 
    onClick={toggleEmailState} 
    onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave}
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
  >
    <img 
      src='img/icons/e-mail.png'
      alt='Email Icon'
      className={`${isEmailIconActive ? '' : 'hidden-icon'}`}
      style={{ ...scaleStyle, opacity: isEmailIconActive ? '1' : '0' }}
    />
    <img 
      src='img/icons/verifier.png'
      alt='Verifier Icon'
      className={`${isEmailIconActive ? 'hidden-icon' : ''}`}
      style={{ ...scaleStyle, opacity: isEmailIconActive ? '0' : '1' }}
    />
  </div>
<div className="team-info" >
  <h5 className="w-text" style={{ 
    opacity: fade ? 0 : 1, 
    transition: 'opacity 500ms' 
  }}>{texteCopie2}</h5>
  {isEmailIconActive && <p className="g-text" style={{ 
    opacity: fade ? 0 : 1
  }}>{texteCopie}</p>}
</div>

                  <div className="team-social-icon">
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
              <a href='https://www.linkedin.com/in/tristan-chenaille-a96a7a227/' target='blank'>
                <div className="single-team-member fadeInUp" data-wow-delay="0.3s" style={{marginBottom: 0}}>
                  <div className="team-member-thumb">
<img src='img/icons/linkedin.png' width={'110px'}></img>                  </div>
                  <div className="team-info">
                    <h5 className="w-text">{t('linkedin_page')}</h5>
                    <p className="g-text">{t('click_to_open')}</p>
                  </div>
                  <div className="team-social-icon">
                  </div>
                </div></a>
              </div>
              
              <div className="col-12 col-sm-6 col-lg-4">
                <a href='https://fr.fiverr.com/tristan_c_/' target='blank'>
                <div className="single-team-member fadeInUp" data-wow-delay="0.4s" style={{marginBottom: 0}}>
                  <div className="team-member-thumb">
                  <img src='img/icons/fiverr.png' width={'110px'}></img>                 

                  </div>
                  <div className="team-info">
                    <h5 className="w-text">{t('fiverr_page')}</h5>
                    <p className="g-text">{t('click_to_open')}</p>
                  </div>
                  <div className="team-social-icon">
                </div>
                
                 
                </div></a> 
              </div>
            </div>
          </div>
        </section>

        {/* <div style={{display: 'flex', justifyContent: 'center'}}> 
          <h4 className="fadeInUp" data-wow-delay="0.4s" style={{padding: 70, margin: 'auto'}}>{t('change_form')}</h4>
        </div> */}

        {/* <div className="row justify-content-center">
          <div className="col-12 col-lg-12">
            <div className="contact_form grediant-borders">
                <form  noValidate>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="group fadeInUp" data-wow-delay="0.2s">
                        <input type="text" name="name" id="name"  />
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{t('name')}</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="group fadeInUp" data-wow-delay="0.3s">
                        <input type="text" name="email" id="email"/>
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{t('email')}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="group fadeInUp" data-wow-delay="0.4s">
                        <input type="text" name="subject" id="subject"/>
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{t('subject')}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="group fadeInUp" data-wow-delay="0.5s">
                        <textarea name="message" id="message"  />
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{t('message')}</label>
                      </div>
                    </div>
                    <div className="col-12 text-center fadeInUp" data-wow-delay="0.6s">
                      <button type="submit" className="btn more-btn">{t('send')}</button>
                    </div>
                  </div>
                </form>
             
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactPage;
